export const arr_char = [
	{
		name: "JC3ちゃん",
		sex: "female",
		jsonUrl: "/_anime_data/jc3_girl1/JC3_girl1_parts.json",
		atlasUrl: "/_anime_data/jc3_girl1/JC3_girl1_parts.atlas",
		viewport: {
			x: -700,
			y: -300,
			width: 1400,
			height: 1700
		}
	},
	{
		name: "JC3ちゃん",
		sex: "female",
		jsonUrl: "/_anime_data/jc3_girl2/JC3_girl2parts.json",
		atlasUrl: "/_anime_data/jc3_girl2/JC3_girl2parts.atlas",
		viewport: {
			x: -700,
			y: -400,
			width: 1400,
			height: 1700
		}
	},
	{
		name: "JC3くん",
		sex: "male",
		jsonUrl: "/_anime_data/jc3_boy1/JC3_boy1_parts.json",
		atlasUrl: "/_anime_data/jc3_boy1/JC3_boy1_parts.atlas",
		viewport: {
			x: -700,
			y: 100,
			width: 1400,
			height: 1700
		}
	},
	{
		name: "JC3くん",
		sex: "male",
		jsonUrl: "/_anime_data/jc3_boy2/JC3_boy2_parts.json",
		atlasUrl: "/_anime_data/jc3_boy2/JC3_boy2_parts.atlas",
		viewport: {
			x: -600,
			y: 100,
			width: 1400,
			height: 1700
		}
	}
];

export const arr_message = {
	female: [
		"今日も来てくれてありがとうございます！",
		"また来てくれて、私も嬉しいです！",
		"今日のニュースはチェックしましたか？",
		"今日も一日、お仕事がんばりましょう！",
		"今日も明るく元気にがんばりましょう！",
		"あなたなら大丈夫です！お互い頑張りましょう。",
		"サイバー犯罪を防ぐために、今日も頑張りましょう！",
		"街の平和を守るために、頑張りましょう！",
		"いつもお仕事ご苦労さまです！",
		"今日もお仕事お疲れさまです。",
		"いつも応援してます！頑張ってくださいね。",
		"残業はしてないですか？無理しないでくださいね。",
		"疲れたときは、無理せず休んでくださいね？",
		"私はいつでも、あなたの味方ですよ！",
		"私にできることがあったら、いつでも言ってくださいね！",
		"あなたが頑張ってるの、私はいつも見てますから！",
		"頑張りすぎてないですか？たまには休憩も大切ですよ。",
		"たまには息抜きも必要です！少し休憩しませんか？",
		"ここで学んだことは、きっとあなたの力になりますよ。",
		"私もサイバーパトロール、行ってきます！"
	],
	male: [
		"今日も来てくれてありがとう！",
		"また来てくれたんだね、嬉しいよ！",
		"今日のニュースはチェックした？",
		"今日も一日、仕事頑張ろう！",
		"君なら大丈夫！お互い頑張ろうね。",
		"サイバー犯罪を防ぐために、今日も頑張ろう！",
		"街の平和を守るために、頑張ろう！",
		"いつもお仕事ご苦労さま！",
		"今日もお仕事お疲れさま。",
		"いつも応援してるよ！頑張ってね！",
		"最近残業してない？無理だけはしないでね。",
		"疲れたときは、無理せず休んでね！",
		"僕はいつでも、君の味方だよ！",
		"僕にできることがあったら、いつでも言ってね！",
		"君が頑張ってるの、いつも見てるから！",
		"頑張りすぎてない？たまには休憩も大事だよ。",
		"たまには息抜きも必要だよ！少し休憩しない？",
		"ここで学んだことは、きっと君の力になるから！",
		"サイバーパトロール行ってくる！",
		"何か困ったことがあったら、相談してね！"
	]
};