<template>
  <div>
    <div id="char_img" class="char_img" style="width: 260px; height: 430px;"></div>
    <dl class="char_msg">
      <dt v-html="char_name"></dt>
      <dd v-html="char_message"></dd>
    </dl>
  </div>
</template>

<script>
import { SpinePlayer } from "@esotericsoftware/spine-player/dist/Player"
import { arr_char, arr_message } from '@/mixin/character_data.js'
export default {
  props: {
    char: Number
  },
  data: () => ({
    char_name: '',
    char_message: '',
  }),
  methods: {
    character() {
      document.getElementById('char_img').innerHTML = ''

      var char = arr_char[this.char];

      // コンテンツの設定
      this.char_name = char.name

      var messages = arr_message[char.sex];
      var message = messages[Math.floor(Math.random() * messages.length)];
      var string = message.split("");
      setTimeout(() => {
        string.forEach((char, index) => {
          setTimeout(() => {
            this.char_message += char;
          }, 70 * index);
        });
      }, 1000);

      // spineの設定
      new SpinePlayer("char_img", {
        animation: "animation",
        showControls: false,
        alpha: true,
        jsonUrl: char.jsonUrl,
        atlasUrl: char.atlasUrl,
        viewport: {
          x: char.viewport.x,
          y: char.viewport.y,
          width: char.viewport.width,
          height: char.viewport.height,
          padLeft: "0%",
          padRight: "0%",
          padTop: "0%",
          padBottom: "0%"
        },
        success: function(player) {
          document.getElementById("char_img").onclick = function() {
            player.animationState.setAnimation(0, "click", false);
            player.animationState.addAnimation(0, "animation");
          };
        }
      });
    }
  }
}
</script>